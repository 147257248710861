import React from 'react'
import './section.scss'

import BackgroundImage from 'gatsby-background-image'
// import sectionStyle from "./section.module.scss"

export const BackgroundSection = ({ backgroundFluid, className, children, style, id }) => {

  style.minHeight = style.minHeight || '100vh'

  return (
    <>
      <div className="anchor" id={id}/>
      <BackgroundImage Tag="section"
                       className={`${className}`}
                       fluid={backgroundFluid}
                       style={style}
      >

        {children}

      </BackgroundImage>
    </>
  )
}


export const Section = ({ children, className, style={}, id }) => {
  style.minHeight = style.minHeight || '50vh'

  return (
    <>
      <div className="anchor" id={id}/>
      <section className={className} style={style}>
        {children}
      </section>
    </>
  )
}


