import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, StaticQuery, Link } from 'gatsby'

import Header from "./header"
import Footer from './footer'
import "./layout.scss"

import { Col, Container, Row } from 'reactstrap'
import ButtonLink from './buttonLink'
import { MDXProvider } from '@mdx-js/react'
import Img from "gatsby-image"

import { BackgroundSection, Section } from "../components/section"


const mdxComponents = {
  // h1: props => <Heading {...props} el={'h1'} />,
  // h2: props => <Heading {...props} el={'h2'} />,
  // img: Image,
  // a: Anchor,
  // pre: AutomatedCodeBlock,

  BackgroundSection: BackgroundSection, 
  Section: Section,

  ButtonLink: ButtonLink,
  Container: Container,
  Row: Row,
  Col: Col,
  Link: Link,
  Img: Img
}


const Layout = ({ children, data, pathName, ...props }) => {

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
#              externalLinks {
#                googleScholarProfile
#                github
#                facebook
#                instagram
#                twitter
#              }
            }
          }
        }
      `}
      render={data => (
        <MDXProvider components={mdxComponents} >
          <Helmet
            title={`${pathName !== undefined ? pathName + ' | ' : ''}${
              data.site.siteMetadata.title
            }`}
            meta={[
              {
                name: 'description',
                content: ""
              },
              {
                name: 'keywords',
                content:
                  ''
              }
            ]}
          >
            <html lang="en" />
          </Helmet>
          <div className="layout">

          <Header
            layoutProps={props}
            siteTitle={data.site.siteMetadata.title}
            pathName={pathName}
          />
          <div className="content">
            {children}
            </div>

            <Footer
              siteTitle={data.site.siteMetadata.title}
              links={data.site.siteMetadata.externalLinks}
            />
            </div>

        </MDXProvider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
