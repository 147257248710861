import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import { Collapse, Container, Nav, Navbar, NavbarToggler } from "reactstrap";
import Img from "gatsby-image"



class Header extends React.Component {

  state = {
    menuOpen: false
  }

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll(event) {
    this.setState({
      scrolledDown: window.pageYOffset > 200
    })
  };

  render() {

    const { layoutProps } = this.props
    const {menuOpen, scrolledDown} = this.state


    return (<StaticQuery
      query={graphql`
{

  allSitePage(sort: {fields: context___frontmatter___order}) {
    edges {
      node {
        path
        context {
          frontmatter {
            title,
            order
          }
        }
      }
    }
  }

  logo: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fixed(width: 150, height: 42) {
        ...GatsbyImageSharpFixed
      }
    }
  }



}
    `}
      render={data => (


        <header>

          <Navbar id="navbar" color="primary" dark expand="md" fixed="top" className={`${scrolledDown ? 'scrolled-down' : ''}`}>
            <Container>
              <div className="navbarbrand">

                <Link to="/">

                  {/* <img
                    src={HeaderImg}
                    width="173"
                    // height="30"
                    // className="d-inline-block align-top"
                    alt=""
                    style={{ marginBottom: "0" }}
                  /> */}
              <Img fixed={data.logo.childImageSharp.fixed} />


                </Link>

              </div>

              <NavbarToggler className="mr-2" onClick={() => {
                this.setState({menuOpen: !menuOpen})
              }} />

              <Collapse isOpen={menuOpen} navbar>
                <Nav className="ml-auto" navbar>

                  {data.allSitePage.edges.map(({ node }, i) => {

                    if (!node.context)
                      return null

                    if (!layoutProps.location)
                      return null

                    const frontmatter = node.context.frontmatter || {}
                    if (!frontmatter.title)
                      return null

                    return <Link key={i} className={`nav-link ${node.path === layoutProps.location.pathname ? 'active' : ''}`} to={node.path}>{frontmatter.title}</Link>
                  })}

                </Nav>
              </Collapse>

            </Container>
          </Navbar>

        </header>
      )}
    />
    )
  }

}


export default Header
