import React from 'react'


const Footer = (props) => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                <div className="col-md-6 text-right">
                    MONCAU PASTISSERIA<br />
                    938 432 421<br />
                    <a href="mailto:canmoncau@canmoncau.cat">comandes@canmoncau.cat</a>

                </div>
                <div className="col-md-6">
                    Carrer Torregassa, 29 - bajo <br />
                    08480 L'AMETLLA DEL VALLÈS <br />
                    Barcelona<br />
                </div>
            </div>
            </div>
        </footer>
    )
}

export default Footer
