import React from 'react'

import { navigate } from 'gatsby'
import { Button } from 'reactstrap'


const ButtonLink = ({to, color='primary', children}) => {
  return <Button color={color} onClick={() => navigate(to)} >{children}</Button>
}

export default ButtonLink
