import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/martijn/workspace/moncau/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <Container mdxType="Container">
        <h1>{`Sobre nosaltres`}</h1>
        <p>{`Tot i que la història de Can Moncau es remonta a més de 100 enrera, actualment, la Tenda de Can Moncau ubicada al Passeig Torregassa 29 de l'Ametlla del Vallès disposa del certificat de Botiga Centenària.`}</p>
        <p>{`Ens agrada el comerç de proximitat i per això el fomentem. Som un bocí d'història del poble que ens agrada cuidar i mimar. `}</p>
        <p>{`Actualment Can Moncau està regentada per la família Plumé. En Joan Plumé, va començar de molt jove d'apranent d'ofici a Can Moncau d'on ara n'és el Gerent.`}</p>
      </Container>
    </Section>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      